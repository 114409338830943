import React from "react";

import SubpageBanner from "../molecules/subpage-banner";
import ArtBlock from "../organisms/art-block";
import CloudBlock from "../organisms/cloud-block";
import Layout from "../layout/Layout";
import Topbar from "../molecules/topbar";
import BlockchainBlock from "../organisms/blockchain-block";
import Seo from "../layout/Seo";

const SolutionsPage = () => {
  return (
    <Layout>
      <Seo name="solutions" title="Blockbit.es | Solutions" />
      <Topbar />
      <SubpageBanner
        title={"Expertise & Solutions"}
        text={
          "We have a solid couple of years of background in a practice that combines consulting, development, and design at scale."
        }
      />
      <BlockchainBlock />
      <CloudBlock />
      <ArtBlock />
    </Layout>
  );
};

export default SolutionsPage;

export const Head = () => <Seo title="Blockbit.es | Solutions" />;
