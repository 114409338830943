import React from "react";
import styled from "styled-components";
import H2 from "../atoms/h2";
import H3 from "../atoms/h3";
import Text1 from "../atoms/text1";

import BlockchainImg from "../images/blockchain-img.svg";
import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  min-height: 683px;
  background-color: ${({ theme }) => theme.colors.neutral.surface};

  @media ${device.laptop} {
    min-height: unset;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 100px 80px 205px;
  gap: 125px;

  @media ${device.desktop} {
    padding: 70px 80px 48px 80px;
  }

  @media ${device.laptop} {
    padding: 70px 40px 48px 40px;
    gap: 40px;
  }

  @media ${device.tablet} {
    padding: 48px 40px 48px 40px;
    gap: 0px;
    position: relative;
  }

  @media ${device.mobile} {
    padding: 48px 20px;
    gap: 0px;
    position: relative;
  }
`;

const CoinsImg = styled.img`
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    width: 350px;
  }

  @media ${device.laptop} {
    width: 300px;
  }

  @media ${device.tablet} {
    width: 80px;
    position: absolute;
    top: 45px;
    right: 40px;
  }

  @media ${device.mobile} {
    width: 60px;
    position: absolute;
    top: 40px;
    right: 20px;
  }
`;

const Description = styled.div`
  max-width: 610px;
`;

const Text = styled(Text1)`
  margin-top: 32px;

  @media ${device.tablet} {
    margin-top: 40px;
  }
`;

const SolutionsListWrapper = styled.div`
  font-size: 54px;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-top: 80px;

  @media ${device.tablet} {
    margin-top: 40px;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style-position: inside;
  margin-top: 24px;
  gap: 16px 40px;
  list-style-position: outside;
  padding-left: 20px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    max-width: 70%;
    list-style-position: outside;
    padding-left: 20px;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

const BlockchainBlock = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <CoinsImg src={BlockchainImg} />
        <Description>
          <H2 isBlue>Blockchain</H2>
          <Text>
            Blockchain is an innovative way of safely managing data in a
            distributed database. Benefit from the Blockchain today with a help
            of our industry insider experts. <br /> We will show you how we
            helped world-class companies such as Casper, a global L1 blockchain
            with Proof of Stake protocol.
          </Text>
          <SolutionsListWrapper>
            <H3 isBlue>Solutions</H3>
            <List>
              <ListItem>Hot and mobile wallets</ListItem>
              <ListItem>blockchain explorers</ListItem>
              <ListItem>Sdk</ListItem>
              <ListItem>Client libraries</ListItem>
              <ListItem>Web3 products</ListItem>
              <ListItem>Nft markets and galleries</ListItem>
              <ListItem>Chain analysis</ListItem>
              <ListItem>Validator tools</ListItem>
            </List>
          </SolutionsListWrapper>
        </Description>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default BlockchainBlock;
