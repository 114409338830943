import React from "react";
import styled from "styled-components";

import H2 from "../atoms/h2";
import Text1 from "../atoms/text1";
import { device } from "../utils/media-queries";

import CloudImg from "../images/cloud-img.svg";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  min-height: 683px;
  background-color: ${({ theme }) => theme.colors.neutral.white};

  @media ${device.laptop} {
    min-height: unset;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  padding: 80px 100px 80px 205px;
  gap: 125px;

  @media ${device.desktop} {
    padding: 70px 80px 48px 80px;
  }

  @media ${device.laptop} {
    padding: 70px 40px 48px 40px;
    gap: 40px;
  }

  @media ${device.tablet} {
    padding: 70px 40px 48px 40px;
    gap: 0px;
    position: relative;
  }

  @media ${device.mobile} {
    padding: 48px 20px 48px 20px;
    gap: 0px;
    position: relative;
  }
`;

const TitleImg = styled.img`
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    width: 350px;
  }

  @media ${device.laptop} {
    width: 300px;
  }

  @media ${device.tablet} {
    width: 80px;
    position: absolute;
    top: 70px;
    right: 40px;
  }

  @media ${device.mobile} {
    width: 60px;
    position: absolute;
    top: 45px;
    right: 20px;
  }
`;

const Description = styled.div`
  max-width: 610px;
`;

const Text = styled(Text1)`
  margin-top: 32px;

  @media ${device.tablet} {
    margin-top: 40px;
  }
`;

const SolutionsListWrapper = styled.div`
  font-size: 54px;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-top: 80px;

  @media ${device.tablet} {
    margin-top: 48px;
  }
`;

const Subtitle = styled.h4`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.main};
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 24px;
  gap: 16px 40px;
  list-style-position: outside;
  padding-left: 20px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

const CloudBlock = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Description>
          <H2 isBlue>Cloud</H2>
          <Text>
            The cloud is an effective and scalable solution for managing and
            storing data. It could be mixed with the blockchain-based product to
            ensure tamper proof data state. <br /> Blockbites is your
            experienced partner of choice for dedicated cloud solutions enhanced
            with blockchain technology.
          </Text>
          <SolutionsListWrapper>
            <Subtitle>Solutions</Subtitle>
            <List>
              <ListItem>Amazon cloud hosted apps</ListItem>
              <ListItem>Lambda functions</ListItem>
              <ListItem>Data storage and migration</ListItem>
              <ListItem>Cloud CI/CD</ListItem>
              <ListItem>
                Experienced with dedicated hosting offerings (Ghost, Gatsby,
                Docusaurus and more)
              </ListItem>
              <ListItem>
                Backing up your data with IPFS, Pinata, SealStorage
              </ListItem>
            </List>
          </SolutionsListWrapper>
        </Description>
        <TitleImg src={CloudImg} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default CloudBlock;
